import { StyleSheet } from 'aphrodite'
import { KnobOffset, SliderEvent } from './models'
import { winObj } from './utils'

export const KNOB_OFFSET: KnobOffset = {
  bottom: -Math.PI / 2,
  left: -Math.PI,
  right: 0,
  top: Math.PI / 2
}

export const RADIAL_SLIDER_STYLES: any = StyleSheet.create({
  mounted: { opacity: 1 },
  radialSlider: {
    display: 'inline-block',
    opacity: 0,
    position: 'relative',
    transition: 'opacity 1s ease-in'
  }
})

const touchSupported: boolean = ('ontouchstart' in winObj)

export const SLIDER_EVENT: SliderEvent = {
  DOWN: touchSupported ? 'touchstart' : 'mousedown',
  UP: touchSupported ? 'touchend' : 'mouseup',
  MOVE: touchSupported ? 'touchmove' : 'mousemove',
}

export const SPREAD_DEGREES: number = 360
