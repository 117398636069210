import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'
import { LabelsComponent } from './models'

const Labels: LabelsComponent = ({
  labelColor = '#333333',
  labelBottom = false,
  labelFontSize = '1rem',
  valueFontSize = '1rem',
  appendToValue = '',
  prependToValue = '',
  verticalOffset = 0,
  hideLabelValue = false,
  label = 'LABEL',
  value = '0'
  }) => {
  const styles: any = StyleSheet.create({

    labels: {
      alignItems: 'center',
      color: `${labelColor}`,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: '0',
      position: 'absolute',
      top: '0',
      userSelect: 'none',
      width: '100%',
      zIndex: 1,
    },

    value: { fontSize: `${valueFontSize}`, position: 'relative' },

    bottomMargin: { marginBottom: `calc(${verticalOffset})` },

    appended: {
      position: 'absolute',
      right: '0',
      top: '0',
      transform: 'translate(100%, 0)'
    },

    prepended: {
      position: 'absolute',
      left: '0',
      top: '0',
      transform: 'translate(-100%, 0)'
    },

    hide: { display: 'none' }

  })

  return (
    <div className={css(styles.labels, hideLabelValue && styles.hide)}>
      { labelBottom || <div style={{fontSize: labelFontSize}}>{label}</div> }
      <div className={css(styles.value, !labelBottom && styles.bottomMargin)}>
        <code>
          <span className={css(styles.prepended)}>{prependToValue}</span>
          {value}
          <span className={css(styles.appended)}>{appendToValue}</span>
        </code>
      </div>
      { labelBottom && <div style={{fontSize: labelFontSize}}>{label}</div> }
    </div>
  )
}

Labels.propTypes = {
  appendToValue: PropTypes.string,
  hideLabelValue: PropTypes.bool,
  label: PropTypes.string,
  labelBottom: PropTypes.bool,
  labelColor: PropTypes.string,
  labelFontSize: PropTypes.string,
  prependToValue: PropTypes.string,
  value: PropTypes.string,
  valueFontSize: PropTypes.string,
  verticalOffset: PropTypes.string,
}

export { Labels }
