import React, { useCallback, useEffect, useReducer, useRef, MutableRefObject, RefObject } from 'react'
import PropTypes from 'prop-types'
import { css } from 'aphrodite'
import { generateRange, getSliderRotation, getRadians, offsetRelativeToDocument, useEventListener } from './utils'
import { Knob } from './knob'
import { KNOB_OFFSET, RADIAL_SLIDER_STYLES, SLIDER_EVENT, SPREAD_DEGREES } from './constants'
import { Labels } from './labels'
import { RadialSliderComponent, RadialSliderState } from './models'
import { reducer } from './reducer'
import { Svg } from './svg'

const RadialSlider: RadialSliderComponent = (
  {
    appendToValue = '',
    children = null,
    data = [],
    dataIndex = 0,
    direction = 1,
    hideLabelValue = false,
    knobColor = '#333333',
    knobPosition = 'bottom',
    label = 'LABEL',
    labelColor = '#333333',
    labelBottom = false,
    labelFontSize = '1rem',
    max = 360,
    min = 0,
    onChange = (value: number) => value,
    prependToValue = '',
    progressColorFrom = '#E96814',
    progressColorTo = '#E96814',
    progressLineCap = 'round',
    progressSize = 8,
    trackColor = '#333333',
    trackSize = 8,
    valueFontSize = '3rem',
    verticalOffset = '1.5rem',
    width = 280
  }
) => {
  const initialState: RadialSliderState = {
    dashFullArray: 0,
    dashFullOffset: 0,
    data: data,
    isDragging: false,
    knob: { x: 0, y: 0 },
    knobPosition: knobPosition,
    label: 0,
    mounted: false,
    offset: 0,
    radians: 0,
    radius: width / 2,
    width: width
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const radialSlider: RefObject<HTMLDivElement> = useRef(null)
  const svgFullPath: MutableRefObject<any> = useRef(null)
  const setKnobPosition: Function = useCallback((radians: number) => {
    const radius: number = (state as any).radius - trackSize / 2
    const offsetRadians: number = radians + KNOB_OFFSET[knobPosition]
    let degrees: number = (offsetRadians > 0 ? offsetRadians
        :
        ((2 * Math.PI) + offsetRadians)) * (SPREAD_DEGREES / (2 * Math.PI))
    // change direction
    const dashOffset: number = (degrees / SPREAD_DEGREES) * (state as any).dashFullArray
    degrees = (getSliderRotation(direction) === -1 ? SPREAD_DEGREES - degrees : degrees)

    const pointsInCircle: number = ((state as any).data.length - 1) / SPREAD_DEGREES
    const currentPoint: number = Math.round(degrees * pointsInCircle)
    if((state as any).data[currentPoint] !== (state as any).label) {
      // props callback for parent
      onChange((state as any).data[currentPoint])
    }
    //@ts-ignore
    dispatch({
      type: 'setKnobPosition',
      payload: {
        dashFullOffset: getSliderRotation(direction) === -1 ? dashOffset : (state as any).dashFullArray - dashOffset,
        label: (state as any).data[currentPoint],
        knob: { x: (radius * Math.cos(radians) + radius), y: (radius * Math.sin(radians) + radius) }
      }
    })
  }, [(state as any).dashFullArray, (state as any).radius, (state as any).data, (state as any).label, knobPosition, direction, onChange])
  const onMouseDown: Function = () => {
    //@ts-ignore
    dispatch({
      type: 'onMouseDown',
      payload: { isDragging: true }
    })
  }
  const onMouseUp: Function = () => {
    //@ts-ignore
    dispatch({
      type: 'onMouseUp',
      payload: { isDragging: false }
    })
  }
  const onMouseMove: Function = useCallback((event: any) => {
    if (!(state as any).isDragging) return
    event.preventDefault()
    let touch: any
    if (event.type === 'touchmove') {
      touch = event.changedTouches[0]
    }
    const mouseXFromCenter: number = (event.type === 'touchmove' ? touch.pageX : event.pageX) -
      (offsetRelativeToDocument(radialSlider).left + (state as any).radius)
    const mouseYFromCenter: number = (event.type === 'touchmove' ? touch.pageY : event.pageY) -
      (offsetRelativeToDocument(radialSlider).top + (state as any).radius)

    const radians: number = Math.atan2(mouseYFromCenter, mouseXFromCenter)
    setKnobPosition(radians)
  }, [(state as any).isDragging, (state as any).radius, setKnobPosition])

  // Get svg path length onmount
  useEffect(() => {
    //@ts-ignore
    dispatch({
      type: 'init',
      payload: {
        mounted: true,
        data: (state as any).data.length ? [...(state as any).data] : [...generateRange(min, max)],
        dashFullArray: svgFullPath.current.getTotalLength ? svgFullPath.current.getTotalLength() : 0,
      }
    })
    // eslint-disable-next-line
  }, [max, min])

  // Set knob position
  useEffect(() => {
    const dataArrayLength: number = (state as any).data.length
    const knobPositionIndex: number = (dataIndex > dataArrayLength - 1) ? dataArrayLength - 1 : dataIndex

    if(!!dataArrayLength) {
      const pointsInCircle: number = SPREAD_DEGREES / dataArrayLength
      const offset: number = getRadians(pointsInCircle) / 2

      //@ts-ignore
      dispatch({
        type: 'setInitialKnobPosition',
        payload: {
          radians: Math.PI / 2 - KNOB_OFFSET[(state as any).knobPosition],
          offset
        }
      })

      if(knobPositionIndex) {
        const degrees: number = getSliderRotation(direction) * knobPositionIndex * pointsInCircle
        const radians: number = getRadians(degrees) - KNOB_OFFSET[(state as any).knobPosition]
        return setKnobPosition(radians+(offset*getSliderRotation(direction)))
      }
      setKnobPosition(-(KNOB_OFFSET[(state as any).knobPosition]*getSliderRotation(direction))+(offset*getSliderRotation(direction)))
    }

    // eslint-disable-next-line
  }, [(state as any).dashFullArray, (state as any).knobPosition, (state as any).data.length, dataIndex, direction])

  useEventListener(SLIDER_EVENT.MOVE, onMouseMove)
  useEventListener(SLIDER_EVENT.UP, onMouseUp)

  return (
    <div className={css(RADIAL_SLIDER_STYLES.radialSlider, (state as any).mounted && RADIAL_SLIDER_STYLES.mounted)} ref={radialSlider}>
      <Svg
        direction={direction}
        label={label.split(' ').join('')}
        progressSize={progressSize}
        progressColorFrom={progressColorFrom}
        progressColorTo={progressColorTo}
        progressLineCap={progressLineCap}
        radiansOffset={(state as any).radians}
        strokeDasharray={(state as any).dashFullArray}
        strokeDashoffset={(state as any).dashFullOffset}
        svgFullPath={svgFullPath}
        trackColor={trackColor}
        trackSize={trackSize}
        width={width}
      />
      <Knob
        isDragging={(state as any).isDragging}
        knobPosition={{ x: (state as any).knob.x, y: (state as any).knob.y }}
        knobColor={knobColor}
        onMouseDown={onMouseDown}
        trackSize={trackSize}
      >
        {children}
      </Knob>
      <Labels
        appendToValue={appendToValue}
        hideLabelValue={hideLabelValue}
        label={label}
        labelColor={labelColor}
        labelBottom={labelBottom}
        labelFontSize={labelFontSize}
        prependToValue={prependToValue}
        value={`${(state as any).label}`}
        valueFontSize={valueFontSize}
        verticalOffset={verticalOffset}
      />
    </div>
  )
}

RadialSlider.propTypes = {
  appendToValue: PropTypes.string,
  data: PropTypes.array,
  dataIndex: PropTypes.number,
  direction: PropTypes.number,
  hideLabelValue: PropTypes.bool,
  knobColor: PropTypes.string,
  knobPosition: PropTypes.string,
  label: PropTypes.string,
  labelBottom: PropTypes.bool,
  labelColor: PropTypes.string,
  labelFontSize: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  prependToValue: PropTypes.string,
  progressColorFrom: PropTypes.string,
  progressColorTo: PropTypes.string,
  progressLineCap: PropTypes.string,
  progressSize: PropTypes.number,
  trackColor: PropTypes.string,
  trackSize: PropTypes.number,
  valueFontSize: PropTypes.string,
  verticalOffset: PropTypes.string,
  width: PropTypes.number
}

export { RadialSlider }
