import { ReducerAction, ReducerFunction, RadialSliderState } from './models'

const reducer: ReducerFunction = (state: RadialSliderState, action: ReducerAction): RadialSliderState => {
  switch (action.type) {
    case 'init':
      return { ...state, ...action.payload }
    case 'setKnobPosition':
      return { ...state, ...action.payload }
    case 'onMouseDown':
    case 'onMouseUp':
      return { ...state, ...action.payload }
    case 'setInitialKnobPosition':
      return { ...state, ...action.payload }
    default:
      throw new Error()
  }
}

export { reducer }
