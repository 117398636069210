import { useEffect, useRef, MutableRefObject, RefObject } from 'react'
import { DocObj, WinObj } from './models'

export let docObj: DocObj = { documentElement: { scrollLeft: 0, scrollTop: 0 } }

// mock window object for SSR
export let winObj: WinObj = { pageXOffset: 0, pageYOffset: 0 }

if (typeof window !== 'undefined') {
  winObj = window
  docObj = document
}

export const getSliderRotation: Function = (num: number) => {
  if(num < 0) return -1
  return 1
}

export const getRadians: Function = (degrees: number) => {
  return degrees * Math.PI / 180
}

export const generateRange: Function = (min: number, max: number) => {
  let rangeOfNumbers: number[] = []
  for(let i: number = min; i <= max; i++) {
    rangeOfNumbers.push(i)
  }
  return rangeOfNumbers
}

export const offsetRelativeToDocument: Function = (ref: RefObject<HTMLDivElement>) => {
  const rect: DOMRect = (ref as any).current.getBoundingClientRect()
  const scrollLeft: number = winObj.pageXOffset || docObj.documentElement.scrollLeft
  const scrollTop: number = winObj.pageYOffset || docObj.documentElement.scrollTop
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
}

export const useEventListener: Function = (eventName: string, handler: Function, element: any = winObj) => {
  const savedHandler: MutableRefObject<any> = useRef(null)
  useEffect(() => { savedHandler.current = handler }, [handler])
  useEffect(() => {
    if(winObj === null) return
    const isSupported: boolean = element && element.addEventListener
    if (!isSupported) return
    // Create event listener that calls handler function stored in ref
    const eventListener: Function = (event: any) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener, {passive: false})
    return () => { element.removeEventListener(eventName, eventListener) }
  },
  [eventName, element])
}
