import React, { ReactElement } from 'react'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'
import { SvgComponent } from './models'

const Svg: SvgComponent = ({
  direction = null,
  label = 'LABEL',
  progressColorFrom = '#333333',
  progressColorTo = '#333333',
  progressLineCap = 'round',
  progressSize = 0,
  radiansOffset = null,
  strokeDashoffset = 0,
  strokeDasharray = '',
  svgFullPath = null,
  trackColor = 'gray',
  trackSize = 0,
  width = 50,
 }): ReactElement => {

  const styles: any = StyleSheet.create({
    svg: { position: 'relative', zIndex: 2 },
    path: {
      transform: `rotate(${radiansOffset}rad) ${direction === -1 ? 'scale(-1, 1)' : 'scale(1, 1)'}`,
      transformOrigin: 'center center'
    }
  })

  const halfTrack: number = trackSize / 2

  const radius: number = width / 2 - halfTrack

  return (
    <svg
      className={css(styles.svg)}
      height={`${width}px`}
      overflow='visible'
      viewBox={`0 0 ${width} ${width}`}
      width={`${width}px`}
    >
      <defs>
        <linearGradient id={label} x1='100%' x2='0%'>
          <stop offset='0%' stopColor={progressColorFrom}/>
          <stop offset='100%' stopColor={progressColorTo}/>
        </linearGradient>
      </defs>
      <circle
        cx={width / 2}
        cy={width / 2}
        fill='none'
        r={radius}
        strokeWidth={trackSize}
        stroke={trackColor}
      />
      <path
        className={css(styles.path)}
        fill='none'
        ref={svgFullPath}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        strokeWidth={progressSize}
        strokeLinecap={progressLineCap !== 'round' ? 'butt' : 'round'}
        stroke={`url(#${label})`}
        d={`
            M ${width / 2}, ${width / 2}
            m 0, -${width / 2 - halfTrack}
            a ${width / 2 - halfTrack},${width / 2 - halfTrack} 0 0,1 0,${width - halfTrack*2}
            a -${width / 2 - halfTrack},-${width / 2 - halfTrack} 0 0,1 0,-${width - halfTrack*2}
          `}
        />
    </svg>
  )
}

Svg.propTypes = {
  width: PropTypes.number,
  label: PropTypes.string,
  direction: PropTypes.number,
  svgFullPath: PropTypes.object,
  strokeDasharray: PropTypes.number,
  strokeDashoffset: PropTypes.number,
  progressColorFrom: PropTypes.string,
  progressColorTo: PropTypes.string,
  progressLineCap: PropTypes.string,
  progressSize: PropTypes.number,
  trackColor: PropTypes.string,
  trackSize: PropTypes.number,
  radiansOffset: PropTypes.number,
}

export { Svg }
